import Vue from "vue";
import { GridPlugin, Toolbar, Page, Filter, Sort, CommandColumn, Edit, Search } from "@syncfusion/ej2-vue-grids";
import { CustomUrlAdaptor } from "@/syncfusion/CustomUrlAdaptor";
import { DataManager } from "@syncfusion/ej2-data";
import SpinnerMixin from "@/mixins/spinner";
import titleMixin from "@/mixins/title";
import gridMixin from "@/mixins/syncfusion/grid";
Vue.use(GridPlugin);
export default Vue.extend({
    mixins: [gridMixin, titleMixin, SpinnerMixin],
    data() {
        return {
            title: this.$t('healthPath.title'),
            customer: {},
            data: new DataManager({
                url: process.env.VUE_APP_API_BASE_URL +
                    `customers/${this.$route.params.customerId}/health-paths/grid-data`,
                removeUrl: process.env.VUE_APP_API_BASE_URL +
                    `customers/${this.$route.params.customerId}/health-paths/grid-delete`,
                adaptor: new CustomUrlAdaptor()
            }),
            toolbarOptions: [
                {
                    text: this.$t('healthPath.create.new'),
                    prefixIcon: "e-plus",
                    id: "HealthPath_Add"
                },
                "Search"
            ],
            commands: [
                {
                    type: "Edit",
                    buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" }
                },
                {
                    type: "Delete",
                    buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" }
                }
            ],
            editSettings: {
                allowEditing: false,
                allowAdding: false,
                allowDeleting: true,
                showDeleteConfirmDialog: true,
                mode: "Dialog"
            },
            sortOptions: {
                columns: [{ field: "Name", direction: "Ascending" }]
            },
            filterOptions: { type: "Menu" },
            searchOptions: {
                fields: ["Name"],
                operator: "contains"
            },
            pageSettings: { pageSize: 10 }
        };
    },
    provide: {
        grid: [CommandColumn, Page, Filter, Sort, Toolbar, Search, Edit]
    },
    methods: {
        convertCompletedWithGym(field, data, column) {
            if (data["CompletedAt"] != null && data["MovedToGymAt"] != null)
                return this.$t("shared.yes");
            return this.$t("shared.no");
        },
        convertCompletedWithoutGym(field, data, column) {
            if (data["CompletedAt"] != null && data["MovedToGymAt"] == null)
                return this.$t("shared.yes");
            return this.$t("shared.no");
        },
        toolbarClick(args) {
            if (args.item.id == "HealthPath_Add") {
                this.$router.push(`/customers/${this.$route.params.customerId}/healthPath/create`);
                return;
            }
        },
        async commandClick(args) {
            if (args.commandColumn.type == "Edit") {
                this.$router.push(`/customers/${this.$route.params.customerId}/healthPath/edit/${args.rowData.Id}`);
                return;
            }
        },
        actionComplete(args) {
            if (args.requestType === "delete") {
                this.$toast.showSuccessToast(this.$t("healthPath.delete.toastSuccessTitle"), this.$t("healthPath.delete.toastSuccessContent", {
                    name: `${args.data[0].Name}`
                }));
            }
        }
    },
    async mounted() {
        this.showSpinner();
        try {
            this.customer = await this.$store.dispatch("customer/get", {
                id: this.$route.params.customerId
            });
            this.setTitle(`${this.$t('shared.healthPathOfCustomer')} ${this.customer.name} ${this.customer.surname}`);
            this.hideSpinner();
        }
        catch (errors) {
            this.hideSpinner();
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
            await this.$router.push("/customers");
        }
    }
});
